import React from "react"
import PropTypes from "prop-types"
import UrlService from "../services/global/urlService"
import PictoMedecinesDouces from "./PictoMedecinesDouces"

const FormulaLevel = ({ rootClass, classIcon, color, level, link, maxLevel, isSvgIcon}) => {
  let fill = []
  let empty = []
  for (let i = 0; i < level; i++) {
    fill.push(i)
  }
  for (let j = 0; j < maxLevel - level; j++) {
    empty.push(j)
  }

  return (
      <>
      {link ?
            <a href={UrlService.addSlashToEndOfUrl(link) || ""}>
              <div className={`formula-level icon ${rootClass || ""}`} style={{ color: color, height: (rootClass != undefined) ? '50px': '27px' }}>
                 { isSvgIcon ? <i style={{ color: color,  height: (rootClass != undefined) ? '50px': '27px'}}> <PictoMedecinesDouces width='19px' height='19px' /> </i> : <i className={classIcon} />}
                {fill.map(i => (
                    <i className="fas fa-circle" key={i} />
                ))}
                {empty.map(i => (
                    <i className="far fa-circle" key={i} />
                ))}
              </div>
            </a> :
              <div className={`formula-level icon ${rootClass || ""}`} style={{ color: color }}>
                 { isSvgIcon ? <i style={{ color: color}}> <PictoMedecinesDouces width='19px' height='19px' /> </i> : <i className={classIcon} />}
                {fill.map(i => (
                    <i className="fas fa-circle" key={i} />
                ))}
                {empty.map(i => (
                    <i className="far fa-circle" key={i} />
                ))}
              </div>
      }
        </>
  )
}

FormulaLevel.propTypes = {
  rootClass: PropTypes.string,
  classIcon: PropTypes.string.isRequired,
  color: PropTypes.string,
  level: PropTypes.number.isRequired,
  maxLevel: PropTypes.number.isRequired
}
export default FormulaLevel
