import React from "react";

  function PictoMedecinesDouces(props) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="126.000000pt" height="137.000000pt" viewBox="0 0 126.000000 137.000000"
            preserveAspectRatio="xMidYMid meet" fill="currentColor" {...props}>

            <g transform="translate(0.000000,137.000000) scale(0.100000,-0.100000)"
            fill="currentColor" stroke="none">
            <path d="M585 1363 c-38 -10 -78 -46 -97 -90 -27 -62 -17 -114 31 -166 32 -34
            45 -40 89 -45 63 -6 102 9 141 53 40 46 48 101 23 157 -22 50 -44 71 -91 87
            -35 11 -61 12 -96 4z"/>
            <path d="M459 990 c-9 -5 -60 -72 -114 -149 -62 -88 -103 -137 -109 -132 -6 4
            -39 29 -73 54 -67 51 -108 60 -136 29 -18 -20 -21 -40 -11 -66 7 -19 203 -173
            224 -177 41 -9 68 13 130 105 38 55 68 90 71 83 4 -7 10 -72 13 -146 l7 -134
            -28 6 c-15 3 -65 11 -110 18 -77 12 -84 11 -103 -6 -15 -14 -20 -29 -18 -54 3
            -36 4 -36 333 -229 181 -105 337 -192 348 -192 30 0 69 47 61 73 -11 36 -17
            40 -185 137 -88 51 -162 97 -166 103 -4 6 16 8 53 5 43 -3 74 -13 114 -38 l55
            -32 53 28 c29 16 83 47 120 69 60 36 67 44 70 76 2 25 -3 40 -18 54 -19 17
            -26 18 -103 6 -45 -7 -95 -15 -110 -18 l-28 -6 6 94 c3 52 8 118 11 147 l6 53
            66 -96 c65 -92 92 -114 132 -106 21 4 217 158 224 177 11 28 7 50 -12 67 -32
            30 -69 20 -144 -35 -39 -29 -72 -53 -74 -53 -2 0 -44 59 -94 130 -51 72 -101
            138 -112 148 -17 15 -42 17 -177 17 -86 0 -163 -5 -172 -10z"/>
            <path d="M388 145 c-51 -34 -62 -58 -46 -92 24 -51 55 -51 141 -2 42 24 77 47
            77 51 0 9 -104 68 -121 68 -8 0 -31 -11 -51 -25z"/>
            </g>
        </svg>
    );
  }
  export default PictoMedecinesDouces;
